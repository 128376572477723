<template>
  <div>
    <div class="q-pa-xl text-center full-height">
      <div class="text-h4 text-light-blue-8 q-pb-xl">
        <div v-if="$store.state.currentUser">Halo {{ $store.state.currentUser.name }},</div>
        Selamat Datang di PPDB Online 2021-2022
      </div>

      <div class="text-left" style="width: 80%; margin: 0 auto;">
        <q-expansion-item
          expand-separator
          default-opened
          label="PROSEDUR PENDAFTARAN"
          popup
          header-class="bg-light-blue-5 text-white text-weight-bold"
        >
          <q-card class="text-subtitle1 ">
            <q-card-section class="bg-grey-1">
              <ol>
                <li>Lihat informasi PPDB dan Sekolah melalui website : https://smpafbs.sch.id/</li>
                <li>Lakukan pendaftaran online dengan memilih menu "Daftar" di menu utama</li>
                <li>Isikan nama, email aktif, dan nomor HP (1 email untuk 1 pendaftar)*</li>
                <li>Cek email yang berisi username dan password</li>
                <li>Pilih menu "Login" dan masuk dengan username dan password yang diperoleh</li>
                <li>Mulai isi formulir pendaftaran (Pastikan data yang diisikan adalah benar dan jujur)</li>
                <li>Setelah selesai, cek kembali email yang berisi nominal dan prosedur transfer biaya pendaftaran</li>
                <li>
                  Pembayaran dapat dilakukan melalui teller, ATM atau m-banking ke nomor akun yang ditentukan. Pastikan
                  nominal sesuai dengan digit yang ditentukan
                </li>
                <li>Upload bukti transfer (verifikasi dilakukan maksimal 1 x 24 jam)</li>
                <li>Download dan cetak formulir pendaftaran pada menu Download Formulir</li>
                <li>Membayar biaya Masuk</li>
                <li>Panitia melakukan verifikasi berkas calon peserta didik</li>
                <li>Mengikuti Observaasi dan Wawancara oleh panitia sesuai dengan jadwal yang sudah ditentukan</li>
                <li>Selamat datang di SMP Al Furqon Boarding School</li>
                <div class="q-pa-md text-subtitle2 text-black">
                  *gunakan email yang berbeda jika sudah pernah mendaftarkan emailnya di website https://smpafbs.sch.id/
                </div>
              </ol>
            </q-card-section>
            <q-separator></q-separator>
            
            <q-card-section class=" text-center ">
              <div class="q-py-xl text-subtitle1 text-grey-8">
                <a v-if="!sudahIsiForm">Persiapkan data dan berkas yang diperlukan, silahkan isi dengan data yang benar</a>
                <a v-else>Anda sudah berhasil mengisi form pendaftaran, download file berikut lalu print untuk diserahkan di waktu daftar ulang</a>
                <div class="q-pa-md">
                  <q-btn
                    v-if="!$store.state.currentUser"
                    color="light-blue-8"
                    @click="startForm"
                    label="Daftar / Login"
                  />
                  <q-btn v-else-if="sudahIsiForm" color="light-blue-8" @click="startForm" label="Download Formulir" />
                  <q-btn v-else color="light-blue-8" @click="startForm" label="Isi Form Pendaftaran" />

                </div>
                <div class="q-pa-md">
                  <q-btn
                    v-if="$store.state.currentUser"
                    flat
                    no-caps
                    @click="uploadForm"
                    color="light-blue-6"
                    label="Upload Bukti Transfer"
                  />
                  <q-btn
                    flat
                    no-caps
                    type="a"
                    :href="'./data/Biaya-SMP-AFBS-2020.pdf'"
                    target="_blank"
                    color="light-blue-6"
                    label="Download Biaya Masuk.pdf"
                  />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </q-expansion-item>
      </div>
    </div>

    <q-dialog v-model="showForm" maximized persistent transition-show="slide-left" transition-hide="slide-down">
      <q-card class="bg-grey-3">
        <div style="width: 100%;">
          <q-linear-progress size="15px" :value="progress1 * step" color="warning">
            <div class="absolute-full flex flex-start">
              <q-badge color="warning" text-color="white" :label="progressLabel1" />
            </div>
          </q-linear-progress>
        </div>
        <div v-if="step == 1" class="form-step">
          <div class="bg-light-blue-8  q-pa-md text-subtitle1 text-white">
            <div style="width: 800px; margin: 0 auto; padding-left: 30px;">
              Data Siswa
            </div>
          </div>
          <div class="form-area">
            <q-card class="q-pa-md q-ma-md">
              <div
                class="text-center text-subtitle1 q-pb-md q-mb-md"
                style="border-bottom: 1px dotted silver;"
              >
                Data Pribadi
              </div>
              <div class="pertanyaan">
                <label for="nama">Nama Lengkap</label>
                <q-input dense outlined ref="nama" v-model="newData.nama"  />
              </div>
              <div class="pertanyaan">
                <label for="alias">Nama Panggilan</label>
                <q-input
                  dense
                  outlined
                  ref="alias"
                  v-model="newData.alias"
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="noid">Kewarganegaraan</label>
                <q-btn-group unelevated spread class="q-mb-md" style="width: 500px;">
                  <q-btn
                    no-caps
                    @click="newData.nation = 'WNI'"
                    :outline="newData.nation == 'WNI' ? false : true"
                    color="light-blue-5"
                    label="WNI"
                  />
                  <q-btn
                    no-caps
                    @click="newData.nation = 'WNA'"
                    :outline="newData.nation == 'WNA' ? false : true"
                    color="light-blue-5"
                    label="WNA"
                  />
                </q-btn-group>
              </div>
              <div class="pertanyaan">
                <label for="jenis_kelamin">Jenis Kelamin</label>
                <q-btn-group unelevated spread class="q-mb-md" style="width: 500px;">
                  <q-btn
                    no-caps
                    @click="newData.jenis_kelamin = 'L'"
                    :outline="newData.jenis_kelamin == 'L' ? false : true"
                    color="light-blue-5"
                    label="Laki-laki"
                  />
                  <q-btn
                    no-caps
                    @click="newData.jenis_kelamin = 'P'"
                    :outline="newData.jenis_kelamin == 'P' ? false : true"
                    color="light-blue-5"
                    label="Perempuan"
                  />
                </q-btn-group>
              </div>
            </q-card>
            <q-card class="q-pa-md q-ma-md">
              <div
                class="text-center text-subtitle1  q-pb-md q-mb-md"
                style="border-bottom: 1px dotted silver;"
              >
                Data Tambahan
              </div>
              <div class="pertanyaan">
                <label for="temp_lahir">Tempat Lahir</label>
                <q-input
                  dense
                  outlined
                  ref="temp_lahir"
                  v-model="newData.temp_lahir"
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="tgl_lahir">Tanggal Lahir (DD/MM/YYYY)</label>
                <!-- <q-input dense outlined ref="tgl_lahir" v-model="newData.tgl_lahir"  :rules="[val => !!val || 'Wajib diisi']" >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="newData.tgl_lahir"  mask="YYYY-MM-DD" />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input> -->
                <q-input
                  dense
                  outlined
                  ref="rt_rw"
                  v-model="newData.tgl_lahir"
                  mask="##/##/####"
                  fill-mask
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="anak_ke">Anak Ke</label>
                <q-input
                  dense
                  outlined
                  type="number"
                  ref="anak_ke"
                  v-model="newData.anak_ke"
                  placeholder=""
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="saudara">Jumlah Saudara Kandung</label>
                <q-input
                  dense
                  outlined
                  type="number"
                  id="saudara"
                  ref="saudara"
                  v-model="newData.saudara_kandung"
                  placeholder=""
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="saudara">Jumlah Saudara Tiri</label>
                <q-input
                  dense
                  outlined
                  type="number"
                  id="saudara"
                  ref="saudara"
                  v-model="newData.saudara_tiri"
                  placeholder=""
                  
                />
              </div>
            </q-card>
            <q-card class="q-pa-md q-ma-md">
              <div
                class="text-center text-subtitle1  q-pb-md q-mb-md"
                style="border-bottom: 1px dotted silver;"
              >
                Alamat
              </div>
              <div class="pertanyaan">
                <label for="add">Nama Jalan</label>
                <q-input
                  dense
                  outlined
                  ref="add"
                  v-model="newData.alamat"
                  placeholder=""
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="rt_rw">RT / RW</label>
                <q-input
                  dense
                  outlined
                  ref="rt_rw"
                  v-model="newData.rt_rw"
                  mask="###/###"
                  fill-mask
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="kel">Desa / Kelurahan</label>
                <q-input
                  dense
                  outlined
                  ref="kel"
                  v-model="newData.kelurahan"
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="kec">Kecamatan</label>
                <q-input
                  dense
                  outlined
                  ref="kec"
                  v-model="newData.kecamatan"
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="kab_kota">Kabupaten / Kota</label>
                <q-input
                  dense
                  outlined
                  ref="kab_kota"
                  v-model="newData.kab_kota"
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="provinsi">Provinsi</label>
                <q-input
                  dense
                  outlined
                  ref="provinsi"
                  v-model="newData.provinsi"
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="pos">Kode POS</label>
                <q-input
                  dense
                  outlined
                  ref="pos"
                  v-model="newData.kode_pos"
                  
                />
              </div>
            </q-card>
          </div>
          <div class="col-2 bottom  row items-end">
            <q-btn outline class="q-px-sm bg-white" label="Back" color="light-blue-8" @click="showForm = false" />
            <q-btn unelevated class="q-px-sm" label="Next" color="light-blue-8" @click="step = 2" />
          </div>
        </div>
        <div v-if="step == 2" class="form-step">
          <div class="bg-light-blue-8  q-pa-md text-subtitle1 text-white">
            <div style="width: 800px; margin: 0 auto; padding-left: 30px;">
              Data Orang Tua
            </div>
          </div>
          <div class="form-area">
            <q-card class="q-pa-md q-ma-md">
              <div
                class="text-center text-subtitle1  q-pb-md q-mb-md"
                style="border-bottom: 1px dotted silver;"
              >
                Identitas Ayah
              </div>
              <div class="pertanyaan">
                <label for="nama">Nama Lengkap</label>
                <q-input
                  dense
                  outlined
                  ref="nama"
                  v-model="newData.nama_ayah"
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="noid">Kewarganegaraan</label>
                <q-btn-group unelevated spread class="q-mb-md" style="width: 500px;">
                  <q-btn
                    no-caps
                    @click="newData.nation_ayah = 'WNI'"
                    :outline="newData.nation_ayah == 'WNI' ? false : true"
                    color="light-blue-5"
                    label="WNI"
                  />
                  <q-btn
                    no-caps
                    @click="newData.nation_ayah = 'WNA'"
                    :outline="newData.nation_ayah == 'WNA' ? false : true"
                    color="light-blue-5"
                    label="WNA"
                  />
                </q-btn-group>
              </div>
              <div class="pertanyaan">
                <label for="nama">Tempat Lahir</label>
                <q-input
                  dense
                  outlined
                  ref="nama"
                  v-model="newData.temp_lahir_ayah"
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="tgl_lahir_ayah">Tanggal Lahir</label>
                <!-- <q-input dense outlined ref="tgl_lahir_ayah" v-model="newData.tgl_lahir_ayah"  :rules="[val => !!val || 'Wajib diisi']" >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="newData.tgl_lahir_ayah"  mask="YYYY-MM-DD" />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input> -->
                <q-input
                  dense
                  outlined
                  ref="rt_rw"
                  v-model="newData.tgl_lahir_ayah"
                  mask="##/##/####"
                  fill-mask
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="nama">Pendidikan Terakhir</label>
                <q-input
                  dense
                  outlined
                  ref="nama"
                  v-model="newData.pend_ayah"
                  placeholder=""
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="nama">Pekerjaan</label>
                <q-input
                  dense
                  outlined
                  ref="nama"
                  v-model="newData.kerja_ayah"
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="nama">No Telp. / HP</label>
                <q-input
                  dense
                  outlined
                  ref="nama"
                  v-model="newData.no_hp_ayah"
                  
                />
              </div>
            </q-card>
            <q-card class="q-pa-md q-ma-md">
              <div
                class="text-center text-subtitle1  q-pb-md q-mb-md"
                style="border-bottom: 1px dotted silver;"
              >
                Identitas Ibu
              </div>
              <div class="pertanyaan">
                <label for="nama">Nama Lengkap</label>
                <q-input
                  dense
                  outlined
                  ref="nama"
                  v-model="newData.nama_ibu"
                  placeholder=""
                 
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="noid">Kewarganegaraan</label>
                <q-btn-group unelevated spread class="q-mb-md" style="width: 500px;">
                  <q-btn
                    no-caps
                    @click="newData.nation_ibu = 'WNI'"
                    :outline="newData.nation_ibu == 'WNI' ? false : true"
                    color="light-blue-5"
                    label="WNI"
                  />
                  <q-btn
                    no-caps
                    @click="newData.nation_ibu = 'WNA'"
                    :outline="newData.nation_ibu == 'WNA' ? false : true"
                    color="light-blue-5"
                    label="WNA"
                  />
                </q-btn-group>
              </div>
              <div class="pertanyaan">
                <label for="nama">Tempat Lahir</label>
                <q-input
                  dense
                  outlined
                  ref="nama"
                  v-model="newData.temp_lahir_ibu"
                 
                />
              </div>
              <div class="pertanyaan">
                <label for="tgl_lahir_ibu">Tanggal Lahir</label>
                <!-- <q-input dense outlined ref="tgl_lahir_ibu" v-model="newData.tgl_lahir_ibu"  :rules="[val => !!val || 'Wajib diisi']" >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="newData.tgl_lahir_ibu"  mask="YYYY-MM-DD" />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input> -->
                <q-input
                  dense
                  outlined
                  ref="rt_rw"
                  v-model="newData.tgl_lahir_ibu"
                  mask="##/##/####"
                  fill-mask
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="pend_ibu">Pendidikan Terakhir</label>
                <q-input
                  dense
                  outlined
                  ref="pend_ibu"
                  v-model="newData.pend_ibu"
                  placeholder=""
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="kerja_ibu">Pekerjaan</label>
                <q-input
                  dense
                  outlined
                  ref="kerja_ibu"
                  v-model="newData.kerja_ibu"
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="no_hp_ibu">No Telp. / HP</label>
                <q-input
                  dense
                  outlined
                  ref="no_hp_ibu"
                  v-model="newData.no_hp_ibu"
                
                />
              </div>
            </q-card>
            <q-card class="q-pa-md q-ma-md">
              <div
                class="text-center text-subtitle1  q-pb-md q-mb-md"
                style="border-bottom: 1px dotted silver;"
              >
                Data Lain
              </div>
              <div class="pertanyaan">
                <label for="email_ortu">Email Aktif</label>
                <q-input
                  dense
                  outlined
                  ref="email_ortu"
                  v-model="newData.email_ortu"
                  placeholder="xxxxx@gmail.com"
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="pendapatan">Pendapatan Perbulan</label>
                <div>
                  <q-radio
                    keep-color
                    v-model="newData.pendapatan"
                    val="Di bawah Rp 3.000.000"
                    label="Di bawah Rp 3.000.000"
                    color="light-blue-5"
                  />
                  <q-radio
                    keep-color
                    v-model="newData.pendapatan"
                    val="Rp 3.000.000 - Rp 6.000.000"
                    label="Rp 3.000.000 - Rp 6.000.000"
                    color="light-blue-5"
                  />
                  <q-radio
                    keep-color
                    v-model="newData.pendapatan"
                    val="Di atas Rp 6.000.000"
                    label="Di atas Rp 6.000.000"
                    color="light-blue-5"
                  />
                </div>
              </div>
            </q-card>
          </div>
          <div class="col-2 bottom  row items-end">
            <q-btn outline class="q-px-sm no-border-radius  bg-white" label="Back" color="light-blue-8" @click="step = 1" />
            <q-btn unelevated class="q-px-sm no-border-radius" label="Next" color="light-blue-8" @click="step = 3" />
          </div>
        </div>
        <div v-if="step == 3" class="form-step">
          <div class="bg-light-blue-8  q-pa-md text-subtitle1 text-white">
            <div style="width: 800px; margin: 0 auto; padding-left: 30px;">
              Data Wali Siswa
            </div>
          </div>
          <div class="form-area">
            <q-card class="q-pa-md q-ma-md">
              <div
                class="text-center text-subtitle1  q-pb-md q-mb-md"
                style="border-bottom: 1px dotted silver;"
              >
                Identitas Wali
              </div>
              <div class="pertanyaan">
                <label for="nama_wali">Nama Lengkap</label>
                <q-input
                  dense
                  outlined
                  ref="nama_wali"
                  v-model="newData.nama_wali"
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="nation_wali">Kewarganegaraan</label>
                <q-btn-group unelevated spread class="q-mb-md" style="width: 500px;">
                  <q-btn
                    no-caps
                    @click="newData.nation_wali = 'WNI'"
                    :outline="newData.nation_wali == 'WNI' ? false : true"
                    color="light-blue-5"
                    label="WNI"
                  />
                  <q-btn
                    no-caps
                    @click="newData.nation_wali = 'WNA'"
                    :outline="newData.nation_wali == 'WNA' ? false : true"
                    color="light-blue-5"
                    label="WNA"
                  />
                </q-btn-group>
              </div>
              <div class="pertanyaan">
                <label for="temp_lahir_wali">Tempat Lahir</label>
                <q-input
                  dense
                  outlined
                  ref="temp_lahir_wali"
                  v-model="newData.temp_lahir_wali"
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="tgl_lahir_wali">Tanggal Lahir</label>
                <!-- <q-input dense outlined ref="tgl_lahir_wali" v-model="newData.tgl_lahir_wali"  :rules="[val => !!val || 'Wajib diisi']" >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="newData.tgl_lahir_wali"  mask="YYYY-MM-DD" />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input> -->
                <q-input
                  dense
                  outlined
                  ref="rt_rw"
                  v-model="newData.tgl_lahir_wali"
                  mask="##/##/####"
                  fill-mask
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="pend_wali">Pendidikan Terakhir</label>
                <q-input
                  dense
                  outlined
                  ref="pend_wali"
                  v-model="newData.pend_wali"
                  placeholder=""
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="kerja_wali">Pekerjaan</label>
                <q-input
                  dense
                  outlined
                  ref="kerja_wali"
                  v-model="newData.kerja_wali"
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="no_hp_wali">No Telp. / HP</label>
                <q-input
                  dense
                  outlined
                  ref="no_hp_wali"
                  v-model="newData.no_hp_wali"
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="alamat_wali">Alamat Lengkap</label>
                <q-input
                  autogrow
                  style="width: 500px;"
                  dense
                  outlined
                  ref="alamat_wali"
                  v-model="newData.alamat_wali"
                  placeholder=""
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="pos_wali">Kode POS</label>
                <q-input
                  dense
                  outlined
                  ref="pos_wali"
                  v-model="newData.pos_wali"
                  
                />
              </div>
            </q-card>
          </div>
          <div class="col-2 bottom  row items-end">
            <q-btn outline class="q-px-sm no-border-radius  bg-white" label="Back" color="light-blue-8" @click="step = 2" />
            <q-btn unelevated class="q-px-sm no-border-radius" label="Next" color="light-blue-8" @click="step = 4" />
          </div>
        </div>
        <div v-if="step == 4" class="form-step">
          <div class="bg-light-blue-8  q-pa-md text-subtitle1 text-white">
            <div style="width: 800px; margin: 0 auto; padding-left: 30px;">
              Data Periodik Siswa
            </div>
          </div>
          <div class="form-area">
            <q-card class="q-pa-md q-ma-md">
              <div
                class="text-center text-subtitle1  q-pb-md q-mb-md"
                style="border-bottom: 1px dotted silver;"
              >
                Data Periodik
              </div>
              <div class="pertanyaan">
                <label for="gol_darah">Golongan Darah</label>
                <q-btn-group unelevated spread class="q-mb-md" style="width: 500px;">
                  <q-btn
                    no-caps
                    @click="newData.gol_darah = 'A'"
                    :outline="newData.gol_darah == 'A' ? false : true"
                    color="light-blue-5"
                    label="A"
                  />
                  <q-btn
                    no-caps
                    @click="newData.gol_darah = 'B'"
                    :outline="newData.gol_darah == 'B' ? false : true"
                    color="light-blue-5"
                    label="B"
                  />
                  <q-btn
                    no-caps
                    @click="newData.gol_darah = 'O'"
                    :outline="newData.gol_darah == 'O' ? false : true"
                    color="light-blue-5"
                    label="O"
                  />
                  <q-btn
                    no-caps
                    @click="newData.gol_darah = 'AB'"
                    :outline="newData.gol_darah == 'AB' ? false : true"
                    color="light-blue-5"
                    label="AB"
                  />
                </q-btn-group>
              </div>
              <div class="pertanyaan">
                <label for="tinggi_badan">Tinggi Badan (cm)</label>
                <q-input
                  dense
                  outlined
                  type="number"
                  ref="tinggi_badan"
                  v-model="newData.tinggi_badan"
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="berat_badan">Berat Badan (Kg)</label>
                <q-input
                  dense
                  outlined
                  type="number"
                  ref="berat_badan"
                  v-model="newData.berat_badan"
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="riwayat_penyakit">Penyakit yang pernah diderita</label>
                <q-input
                  dense
                  outlined
                  ref="riwayat_penyakit"
                  v-model="newData.riwayat_penyakit"
                  placeholder=""
                />
              </div>
              <div class="pertanyaan">
                <label for="kelainan">Kelainan jasmani / lainnya</label>
                <q-input dense outlined ref="kelainan" v-model="newData.kelainan" placeholder="" />
              </div>
            </q-card>
            <div class="col-2 bottom  row items-end">
              <q-btn outline class="q-px-sm no-border-radius  bg-white" label="Back" color="light-blue-8" @click="step = 3" />
              <q-btn unelevated class="q-px-sm no-border-radius" label="Next" color="light-blue-8" @click="step = 5" />
            </div>
          </div>
        </div>
        <div v-if="step == 5" class="form-step">
          <div class="bg-light-blue-8  q-pa-md text-subtitle1 text-white">
            <div style="width: 800px; margin: 0 auto; padding-left: 30px;">
              Data Pendidikan Sebelumnya
            </div>
          </div>
          <div class="form-area">
            <q-card class="q-pa-md q-ma-md">
              <div
                class="text-center text-subtitle1 q-pb-md q-mb-md"
                style="border-bottom: 1px dotted silver;"
              >
                Pendidikan
              </div>
              <div class="pertanyaan">
                <label for="sekolah_asal">Nama Sekolah</label>
                <q-input
                  dense
                  autogrow
                  style="width: 500px;"
                  for="sekolah_asal"
                  outlined
                  ref="Jl. Pahlawan No. 20, ..."
                  v-model="newData.asal_sekolah"
                 
                />
              </div>
              <div class="pertanyaan">
                <label for="alm_sekolah_asal">Alamat Sekolah</label>
                <q-input
                  dense
                  autogrow
                  style="width: 500px;"
                  for="alm_sekolah_asal"
                  outlined
                  ref="Jl. Pahlawan No. 20, ..."
                  v-model="newData.alamat_sekolah_asal"
                  
                />
              </div>
              <div class="pertanyaan">
                <label for="nisn">NISN</label>
                <q-input
                  dense
                  outlined
                  ref="nisn"
                  v-model="newData.nisn"
                  placeholder="9900087XXX"
             
                />
              </div>
              <div class="pertanyaan">
                <label for="no_ijazah">No. Ijazah / STTB</label>
                <q-input
                  dense
                  outlined
                  ref="no_ijazah"
                  v-model="newData.no_ijazah"
                  placeholder="DN-03 Dd 109XXX"
                  
                />
              </div>
            </q-card>
            <q-card class="q-pa-md q-ma-md">
              <div
                class="text-center text-subtitle1 q-pb-md q-mb-md"
                style="border-bottom: 1px dotted silver;"
              >
                Prestasi
              </div>
              <q-card-section>
                <q-card v-for="(x, i) in listPrestasi" :key="i" class="full-width q-pa-sm q-my-md">
                  <div class="row justify-between">
                    <div class="col-8 text-bold">{{ x.nama_prestasi }}</div>
                    <div class="col-4 text-teal-7 text-caption text-right">{{ x.bidang }}</div>
                  </div>
                  <div class="row justify-between">
                    <div class="col-8 text-teal-7 text-caption">{{ x.instansi }}</div>
                    <div class="col-4 text-teal-7 text-caption text-right">{{ x.tahun }}</div>
                  </div>
                </q-card>
                <div class="row full-width justify-center">
                  <q-btn
                    flat
                    outlined
                    @click="newPrestasi = true"
                    color="light-blue-6"
                    style="width: 100px;"
                    label="+ Prestasi"
                    no-caps
                  />
                </div>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-2 bottom  row items-end">
            <q-btn outline class="q-px-sm no-border-radius  bg-white " label="Back" color="light-blue-8" @click="step = 4" />
            <q-btn unelevated class="q-px-sm no-border-radius" label="Submit" color="light-blue-8" @click="onSubmit" />
          </div>
        </div>
      </q-card>
      <q-dialog v-model="newPrestasi" persistent>
        <q-card>
          <q-card-section class="bg-light-blue-8 text-white text-center">
            Tambah Data Prestasi
          </q-card-section>
          <q-card-section style="width: 400px;">
            <label class="text-grey">Nama Prestasi</label>
            <q-input
              dense
              outlined
              v-model="prestasi.nama_prestasi"
              class="full-width"
             
            />
            <label class="text-grey">Bidang</label>
            <q-input
              dense
              outlined
              v-model="prestasi.bidang"
              class="full-width"
            
            />
            <label class="text-grey">Penyelenggara</label>
            <q-input
              dense
              outlined
              v-model="prestasi.instansi"
              class="full-width"
          
            />
            <label class="text-grey">Tahun</label>
            <q-input
              dense
              outlined
              v-model="prestasi.tahun"
              class="full-width"
            
            />

            <div class="row justify-evenly q-pt-lg">
              <q-btn
                outline
                color="light-blue-8"
                class="q-mx-sm"
                @click="
                  newPrestasi = false;
                  prestasi = {};
                "
                label="Batal"
                style="width: 100px;"
                no-caps
              />
              <q-btn
                unelevated
                color="light-blue-8"
                class="q-mx-sm"
                @click="addPrestasi"
                label="Simpan"
                style="width: 100px;"
                no-caps
              />
            </div>
          </q-card-section>
        </q-card>
      </q-dialog>
    </q-dialog>
    <q-dialog v-model="alert">
      <q-card class="q-pa-md">
        <q-card-section>
          Anda belum isi formulir pendaftaran!
        </q-card-section>
        <q-card-sectio class="row justify-center">
          <q-btn v-close-popup flat color="light-blue-6" @click="showForm = true" label="isi formulir" />
        </q-card-sectio>
      </q-card>
    </q-dialog>
    <q-dialog v-model="alert2">
      <q-card class="q-pa-md">
        <q-card-section>
          Anda sudah upload bukti transfer, Apakah anda akan upload ulang?
        </q-card-section>
        <q-card-sectio class="row justify-center">
          <q-btn no-caps v-close-popup flat color="light-blue-6" label="Tidak" />
          <q-btn
            no-caps
            v-close-popup
            flat
            color="light-blue-6"
            @click="$router.push('/pendaftaran/konfirmasi')"
            label="Ya"
          />
        </q-card-sectio>
      </q-card>
    </q-dialog>

    <q-page-container class="bg-white full-width">
      <router-view />
    </q-page-container>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "Home",
  data() {
    return {
      sudahIsiForm: false,
      progress1: 0.2,
      step: 1,
      showForm: false,
      // step: 5,
      // showForm: true,

      newData: {
        nation: "WNI",
        nation_ayah: "WNI",
        nation_ibu: "WNI",
        nation_wali: "WNI",
        jenis_kelamin: "L",
        gol_darah: "O",
      },
      listPrestasi: [],
      prestasi: {},
      newPrestasi: false,
      alert: false,
      alert2: false,
    };
  },
  mounted() {
    this.newData.username = this.$store.state.currentUser.username;
    this.newData.email_ortu = this.$store.state.currentUser.username;
    this.cekPembayaran()
    // this.$http.get("/pendaftar_bynisn/9926171505", {})
    // .then(async (result) => {
    //   this.newData = result.data
    //   this.newData.id = null

    //   this.$http.get("/prestasi_pendaftar/9926171505", {})
    //   .then(async (result) => {
    //     this.prestasi = result.data
    //   });

    // });
  },
  methods: {
    uploadForm() {
      this.$http
        .get("/get_status_pendaftaran/" + this.$store.state.currentUser.username, {})
        .then((result) => {
          if (!result.data.file_transfer) this.$router.push("/pendaftaran/konfirmasi");
          else if (result.data.status == "N") this.alert2 = true;
          else if (result.data.status == "Y") {
            let routeData = this.$router.resolve({ name: "form-final", params: { nisn: result.data.nisn } });
            window.open(routeData.href, "_blank");
          }
        })
        .catch((err) => {
          this.alert = true;
        })
        .catch((err) => {
          this.alert = true;
        });
    },
     async cekPembayaran() {
       await this.$http
        .get("/get_status_pendaftaran/" + this.$store.state.currentUser.username, {})
        .then((result) => {
          this.sudahIsiForm = true
        })
        .catch((err) => {
          this.sudahIsiForm = false
        });
    },
    startForm() {
      if (this.$store.state.currentUser) {
        this.$http
          .get("/get_status_pendaftaran/" + this.$store.state.currentUser.username, {})
          .then((result) => {
            let routeData = this.$router.resolve({ name: "form-final", params: { nisn: result.data.nisn } });
            window.open(routeData.href, "_blank");
          })
          .catch((err) => {
            this.showForm = true;
          });
      } else {
        this.$router.push("/buat-akun");
      }
    },
    addPrestasi() {
      this.listPrestasi.push(this.prestasi);
      this.prestasi = {};
      this.newPrestasi = false;
    },
    onSubmit() {
      this.newData.tgl_lahir = this.newData.tgl_lahir
        .split("/")
        .reverse()
        .join("-");
      this.newData.tgl_lahir_ayah = this.newData.tgl_lahir_ayah
        .split("/")
        .reverse()
        .join("-");
      this.newData.tgl_lahir_ibu = this.newData.tgl_lahir_ibu
        .split("/")
        .reverse()
        .join("-");
      this.newData.tgl_lahir_wali = this.newData.tgl_lahir_wali
        .split("/")
        .reverse()
        .join("-");
      this.$http.post("/pendaftar_baru", this.newData, {}).then((result) => {
        var length = this.listPrestasi.length;
        for (var i = 0; i < length; i++) {
          this.listPrestasi[i].id_pendaftar = result.data;
          this.listPrestasi[i].nama = this.newData.nama;
          this.listPrestasi[i].nisn = this.newData.nisn;
          this.$http.post("/prestasi_pendaftar", this.listPrestasi[i], {}).then((resp) => {});
        }

        this.$http.post("/notif_pendaftaran/" + result.data + "/" + this.newData.username, {}).then((result) => {});

        this.$q.notify({
          icon: "done",
          color: "positive",
          message: "Pengisian formulir berhasil, Silahkan cek email anda",
        });

        this.cekPembayaran()
        this.step = 1;
        this.showForm = false;
        this.newData = {
          nation: "WNI",
          nation_ayah: "WNI",
          nation_ibu: "WNI",
          nation_wali: "WNI",
          jenis_kelamin: "L",
          gol_darah: "O",
        };
      });
    },
  },
  computed: {
    maxHeight() {
      return this.$q.screen.height;
    },
    progressLabel1() {
      return this.step + " of " + 5;
    },
  },
};
</script>
<style lang="scss" scoped>
.form {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: #fff;
  // overflow: auto;
}

.pertanyaan {
  padding-bottom: 8px;
  display: flex;
  align-content: stretch;
  label {
    color: grey;
    width: 250px;
  }
  .q-input {
    width: 500px;
  }
}
.form-step {
  overflow: auto;
  height: calc(100% - 15px);
}
.form-area {
  width: 800px;
  margin: 0 auto;
}
.bottom {
  width: 800px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
input {
  background-color: #fff;
}
.q-field__native,
.q-field__input {
  background-color: #fff;
}
</style>
